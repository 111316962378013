import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import theme from '../config/theme'
import Curtain from '../components/Curtain'
import Footer from '../components/Footer'
import GlobalStyles from '../styled/GlobalStyles'
import Header from '../components/Header'
import Main from '../styled/Main'
import ModeProvider from '../components/ModeProvider'

function Layout(props = {}) {
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    setLoaded(true)
  }, [setLoaded])

  return (
    <ModeProvider>
      {mode => (
        <ThemeProvider
          theme={{
            ...theme,
            colors: theme.colors[mode],
          }}
        >
          <GlobalStyles mode={mode} />
          <Curtain loaded={loaded} />
          <Header />
          <Main {...props}>{props.children}</Main>
          <Footer />
        </ThemeProvider>
      )}
    </ModeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
