import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

const Nav = styled.nav`
  align-items: center;
  display: flex;
  font-size: ${themeGet('fontSizes.s')};
  justify-content: center;

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    justify-content: flex-start;
    margin-left: auto;
  }
`

const NavLink = styled.a`
  color: ${themeGet('colors.medium')};
  font-family: ${themeGet('fonts.heading')};
  font-size: ${themeGet('fontSizes.xxs')};
  display: block;
  letter-spacing: 0.0625rem;
  text-decoration: none;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: ${themeGet('space.l')};
  }
`

Nav.Link = NavLink

export default Nav
