function rem(target, context = 16) {
  const targetValue = target.split('px')[0]
  const value = targetValue / context
  return String(`${value}rem`)
}

const theme = {
  breakpoints: {
    sm: rem('480px'),
    md: rem('768px'),
    lg: rem('1024px'),
    xl: rem('1350px'),
  },
  colors: {
    light: {
      primary: '#557b91',
      bg: '#fff',
      fg: '#2b3a40',
      medium: '#7f888c',
      border: '#e9ebeb',
      light: '#f1f0f2',
    },
    dark: {
      primary: '#557b91',
      bg: '#1b2529',
      fg: '#fff',
      medium: '#7f888c',
      border: '#2b3a40',
      light: '#131a1d',
    },
  },
  fonts: {
    heading: 'brandon-grotesque, sans-serif',
    base: 'ff-tisa-web-pro, serif',
    mono: `"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace`,
  },
  fontSizes: {
    base: rem('22px'),
    xxs: rem('12px'),
    xs: rem('14px'),
    s: rem('18px'),
    l: rem('26px'),
    xl: rem('28px'),
    h1: rem('72px'),
    h2: rem('36px'),
    h3: rem('22px'),
    h4: rem('18px'),
  },
  fontWeights: {
    normal: '400',
    bold: '700',
    black: '900',
  },
  lineHeights: {
    base: '1.5',
    heading: '1.2',
  },
  radii: {
    base: rem('4px'),
  },
  shadows: {
    base: '0 2px 3px rgba(0, 0, 0, 0.15)',
  },
  space: {
    base: rem('16px'),
    xs: rem('5px'),
    s: rem('10px'),
    l: rem('32px'),
    xl: rem('60px'),
    xxl: rem('120px'),
    xxxl: rem('240px'),
  },
}

export default theme
