import React from 'react'
import { Link } from 'gatsby'

import Box from '../styled/Box'
import FlexGrid from '../styled/FlexGrid'
import LogoMark from './LogoMark'
import StyledLogo from '../styled/Logo'

function Logo(props = {}) {
  return (
    <FlexGrid alignItems="center" display={{ _: 'block', md: 'flex' }}>
      <FlexGrid.Item>
        <StyledLogo as={Link} to="/">
          <LogoMark />
        </StyledLogo>
      </FlexGrid.Item>
      <FlexGrid.Item>
        <StyledLogo.Title>Drew Barontini</StyledLogo.Title>
        <StyledLogo.Subtitle>
          Product Director at{' '}
          <Box as="a" href="https://differential.com">
            Differential
          </Box>
        </StyledLogo.Subtitle>
      </FlexGrid.Item>
    </FlexGrid>
  )
}

export default Logo
