import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { system } from './lib/system'

const Logo = styled.div`
  display: block;
  margin-bottom: ${themeGet('space.s')};

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    margin-bottom: 0;
  }

  ${system}
`

const LogoTitle = styled.b`
  color: ${themeGet('colors.fg')};
  display: block;
  font-family: ${themeGet('fonts.heading')};
  font-size: ${themeGet('fontSizes.xs')};
  font-weight: ${themeGet('fontWeights.black')};
  letter-spacing: 0.0625rem;
  line-height: ${themeGet('lineHeights.heading')};
  margin-bottom: ${themeGet('space.xs')};
  text-transform: uppercase;

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    margin-bottom: 0;
  }

  ${system}
`

const LogoSubtitle = styled.span`
  display: block;
  font-size: ${themeGet('fontSizes.xs')};
  margin-bottom: ${themeGet('space.l')};

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    font-size: ${themeGet('fontSizes.xxs')};
    margin-bottom: 0;
  }
`

Logo.Title = LogoTitle
Logo.Subtitle = LogoSubtitle

export default Logo
