import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import themeGet from '@styled-system/theme-get'

import { system, propTypes } from './lib/system'

const gutter = props => themeGet(`space.${props.gutter}`)(props)

const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${gutter};
  > * {
    padding-left: ${gutter};
  }
  ${system}
`

const FlexGridItem = styled.div`
  ${system}
`

FlexGrid.propTypes = {
  ...propTypes,
  gutter: PropTypes.string,
}

FlexGrid.defaultProps = {
  gutter: 'base',
}

FlexGridItem.propTypes = {
  ...propTypes,
}

FlexGrid.Item = FlexGridItem

export default FlexGrid
