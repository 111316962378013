import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

const Footer = styled.footer`
  border-top: 1px solid ${themeGet('colors.border')};
  padding: ${themeGet('space.l')} ${themeGet('space.base')};
  padding-bottom: ${themeGet('space.xl')};

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    padding-left: ${themeGet('space.xl')};
    padding-right: ${themeGet('space.xl')};
  }
`

const FooterIconLinks = styled.div`
  justify-content: center;
  margin-bottom: ${themeGet('space.s')};
`

const FooterTextLinks = styled.ul`
  align-items: center;
  display: flex;
  font-size: ${themeGet('fontSizes.xs')};
  justify-content: center;

  > * {
    display: block;

    &:not(:last-child) {
      margin-right: ${themeGet('space.s')};

      &::after {
        color: ${themeGet('colors.medium')};
        content: '/';
        margin-left: ${themeGet('space.s')};
      }
    }
  }
`

Footer.TextLinks = FooterTextLinks
Footer.IconLinks = FooterIconLinks

export default Footer
