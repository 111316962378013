import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

const Curtain = styled.div`
  align-items: center;
  background-color: ${themeGet('colors.primary')};
  bottom: 0;
  content: '';
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  transform: ${props => (props.loaded ? `translateY(-100%)` : `translateY(0)`)};
  transition: transform 0.6s 0.5s ease-out;
  width: 100%;
  z-index: 10;

  > svg {
    opacity: ${props => (props.loaded ? `1` : `0`)};
    transform: scale(2);
    transition: 0.5s ease-in-out;
  }
`

export default Curtain
