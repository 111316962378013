import { createGlobalStyle } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 16px;
  }

  html, body {
    height: 100%;
  }

  body {
    background-color: ${themeGet(`colors.bg`)};
    border-top: 10px solid ${themeGet('colors.primary')};
    color: ${themeGet('colors.fg')};
    font-family: ${themeGet('fonts.base')};
    font-size: ${themeGet('fontSizes.s')};
    line-height: 1.4;
    position: relative;

    @media screen and (min-width: ${themeGet('breakpoints.md')}) {
      line-height: ${themeGet('lineHeights.base')};
      font-size: ${themeGet('fontSizes.base')};
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${themeGet('fonts.heading')};
    font-weight: ${themeGet('fontWeights.black')};
    line-height: ${themeGet('lineHeights.heading')};
    margin: 0;
    margin-bottom: ${themeGet('space.s')};
  }

  h1 {
    font-size: ${themeGet('fontSizes.h2')};

    @media screen and (min-width: ${themeGet('breakpoints.md')}) {
      font-size: ${themeGet('fontSizes.h1')};
    }
  }

  h2 {
    font-size: ${themeGet('fontSizes.xl')};

    @media screen and (min-width: ${themeGet('breakpoints.md')}) {
      font-size: ${themeGet('fontSizes.h2')};
    }
  }

  h3,
  h4 {
    text-transform: uppercase;
  }

  h3 {
    font-size: ${themeGet('fontSizes.h4')};

    @media screen and (min-width: ${themeGet('breakpoints.md')}) {
      font-size: ${themeGet('fontSizes.h3')};
    }
  }

  h4 {
    font-size: ${themeGet('fontSizes.xs')};

    @media screen and (min-width: ${themeGet('breakpoints.md')}) {
      font-size: ${themeGet('fontSizes.h4')};
    }
  }

  pre,
  code {
    font-family: ${themeGet('fonts.mono')} !important;
  }

  pre {
    ${props =>
      props.mode === 'dark' &&
      `
      background: ${themeGet('colors.light')(props)} !important;
    `}

  }

  p > code {
    background-color: ${themeGet('colors.light')};
    border-radius: ${themeGet('radii.base')};
    color: ${themeGet('colors.fg')} !important;
    font-size: ${themeGet('fontSizes.s')};
    padding: ${themeGet('space.xs')} ${themeGet('space.s')};

    ${props =>
      props.mode === 'light' && `box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);`}
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  a {
    color: ${themeGet('colors.primary')};
    transition: 0.4s ease-in-out;
  }

  a:focus,
  a:hover,
  a:active,
  a[data-state='active'] {
    color: ${themeGet('colors.fg')};
  }
`

export default GlobalStyles
