import React from 'react'
import PropTypes from 'prop-types'

const ModeStateContext = React.createContext()
const ModeDispatchContext = React.createContext()

const KEY = 'db_mode'
const DEFAULT_MODE = 'light'

function ModeProvider(props = {}) {
  const defaultValue = getDefaultValue()
  const [mode, setMode] = React.useState(defaultValue)

  function getDefaultValue() {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem(KEY) || DEFAULT_MODE
    }
    return DEFAULT_MODE
  }

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(KEY, mode)
    }
  }, [mode])

  return (
    <ModeStateContext.Provider value={mode}>
      <ModeDispatchContext.Provider value={setMode}>
        {typeof props.children === 'function'
          ? props.children(mode)
          : props.children}
      </ModeDispatchContext.Provider>
    </ModeStateContext.Provider>
  )
}

// const state = useModeState();
function useModeState() {
  const context = React.useContext(ModeStateContext)
  if (context === undefined) {
    throw new Error(`useModeState must be used within a Provider`)
  }
  return context
}

// const setState = useModeDispatch();
function useModeDispatch() {
  const context = React.useContext(ModeDispatchContext)
  if (context === undefined) {
    throw new Error(`useModeDispatch must be used within a Provider`)
  }
  return context
}

// const [state, setState] = useMode();
function useMode() {
  const context = [useModeState(), useModeDispatch()]
  if (context === undefined) {
    throw new Error(`useMode must be used within a Provider`)
  }
  return context
}

ModeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.func,
  ]),
}

export { ModeProvider as default, useMode, useModeState, useModeDispatch }
