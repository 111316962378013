import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Box from '../styled/Box'
import FlexGrid from '../styled/FlexGrid'
import StyledFooter from '../styled/Footer'

function Footer(props = {}) {
  return (
    <StyledFooter>
      <StyledFooter.IconLinks as={FlexGrid}>
        <FlexGrid.Item>
          <Box as="a" href="/rss.xml" title="RSS Feed" aria-label="RSS Feed">
            <Icon>
              <path d="M4 11a9 9 0 019 9M4 4a16 16 0 0116 16" />
              <circle cx="5" cy="19" r="1" />
            </Icon>
          </Box>
        </FlexGrid.Item>
        <FlexGrid.Item>
          <Box
            as="a"
            href="https://twitter.com/drewbarontini"
            title="Twitter"
            aria-label="Twitter"
          >
            <Icon>
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
            </Icon>
          </Box>
        </FlexGrid.Item>
        <FlexGrid.Item>
          <Box
            as="a"
            href="https://github.com/drewbarontini"
            title="GitHub"
            aria-label="GitHub"
          >
            <Icon>
              <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 00-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0020 4.77 5.07 5.07 0 0019.91 1S18.73.65 16 2.48a13.38 13.38 0 00-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 005 4.77a5.44 5.44 0 00-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 009 18.13V22" />
            </Icon>
          </Box>
        </FlexGrid.Item>
      </StyledFooter.IconLinks>
      <StyledFooter.TextLinks>
        <Box as="li">
          <Box as="a" href="/">
            About
          </Box>
        </Box>
        <Box as="li">
          <Box as="a" href="/blog">
            Blog
          </Box>
        </Box>
        <Box as="li">
          <Box as={Link} to="/uses">
            Uses
          </Box>
        </Box>
        <Box as="li">
          <Box as={Link} to="/info">
            Info
          </Box>
        </Box>
      </StyledFooter.TextLinks>
    </StyledFooter>
  )
}

function Icon({ children }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {children}
    </svg>
  )
}

Icon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

export default Footer
