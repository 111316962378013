import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

const Header = styled.header`
  align-items: center;
  border-bottom: 1px solid ${themeGet('colors.border')};
  margin-bottom: ${themeGet('space.xl')};
  padding: ${themeGet('space.l')} ${themeGet('space.xl')};
  text-align: center;

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    display: flex;
    margin-bottom: ${themeGet('space.xxl')};
    text-align: left;
  }
`

export default Header
