import React from 'react'
import PropTypes from 'prop-types'

import Box from '../styled/Box'
import FancyHeader from '../components/FancyHeader'

function PageHeader(props = {}) {
  return (
    <Box as={FancyHeader} mb={{ _: 'l', md: 'xl' }}>
      <Box as="h1" textTransform="uppercase">
        {props.title}
      </Box>
      {props.description && (
        <Box as="p" fontSize={{ md: 'l' }}>
          {props.description}
        </Box>
      )}
    </Box>
  )
}

PageHeader.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default PageHeader
