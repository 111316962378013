import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Box from '../styled/Box'
import StyledHeader from '../styled/Header'
import Logo from '../components/Logo'
import { useMode } from '../components/ModeProvider'
import StyledNav from '../styled/Nav'

function Header(props = {}) {
  const [mode, setMode] = useMode()
  const isActive = ({ isCurrent }) =>
    isCurrent ? { 'data-state': 'active' } : null
  const newMode = mode === 'light' ? 'dark' : 'light'

  return (
    <StyledHeader>
      <Logo />
      <StyledNav>
        <StyledNav.Link as={Link} to="/" getProps={isActive}>
          <Box display="block" textAlign="center">
            <Icon>
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
              <circle cx="12" cy="7" r="4" />
            </Icon>
          </Box>
          About
        </StyledNav.Link>
        <StyledNav.Link as={Link} to="/blog" getProps={isActive}>
          <Box display="block" textAlign="center">
            <Icon>
              <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
              <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
            </Icon>
          </Box>
          Blog
        </StyledNav.Link>
        <StyledNav.Link
          href="#"
          onClick={event => {
            event.preventDefault()
            setMode(newMode)
          }}
        >
          <Box display="block" textAlign="center">
            {newMode === 'light' ? (
              <Icon>
                <circle cx="12" cy="12" r="5" />
                <path d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" />
              </Icon>
            ) : (
              <Icon>
                <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z" />
              </Icon>
            )}
          </Box>
          {newMode}
        </StyledNav.Link>
      </StyledNav>
    </StyledHeader>
  )
}

function Icon({ children }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {children}
    </svg>
  )
}

Icon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

export default Header
