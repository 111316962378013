import React from 'react'
import PropTypes from 'prop-types'

import StyledCurtain from '../styled/Curtain'

function Curtain(props = {}) {
  return (
    <StyledCurtain loaded={props.loaded}>
      <svg
        width="50"
        height="58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.674 36.016a1.37 1.37 0 100-2.74 1.37 1.37 0 000 2.74zM39.327 36.016a1.37 1.37 0 100-2.74 1.37 1.37 0 000 2.74z"
          fill="#ffffff"
        />
        <path
          d="M25 46.461c2.854 0 5.194-1.484 5.194-3.253H19.806c0 1.77 2.34 3.253 5.194 3.253z"
          fill="#2B3A40"
        />
        <path
          d="M47.831 29.966c1.712-12.729-7.192-24.43-7.192-24.43 0 7.82-3.082 9.875-4.11 10.389-.056-.057-.114-.057-.17-.115C41.038 9.19 35.901 0 35.901 0 32.877 11.073 17.98 4.966 7.477 14.612c-4.68 4.28-5.536 10.045-5.023 15.068A5.998 5.998 0 000 34.532c0 2.34 1.37 4.338 3.368 5.308C5.65 49.715 14.498 57.078 25 57.078c10.56 0 19.406-7.363 21.632-17.238C48.63 38.87 50 36.872 50 34.532a5.944 5.944 0 00-2.169-4.566zm-6.507-5.88c.913 1.37 1.656 2.912 2.226 4.51h-9.76a23.442 23.442 0 007.534-4.51zm-32.705 0a24.338 24.338 0 007.591 4.51H6.393a22.085 22.085 0 012.226-4.51zM25 54.453c-8.904 0-16.381-5.936-18.779-14.041h37.615C41.38 48.516 33.847 54.452 25 54.452zm19.406-16.838H5.594a22.068 22.068 0 01-.172-2.74c0-1.198.115-2.34.286-3.481h38.527c.229 1.141.285 2.283.285 3.481.058.914 0 1.827-.114 2.74z"
          fill="#2B3A40"
        />
      </svg>
    </StyledCurtain>
  )
}

Curtain.propTypes = {
  loaded: PropTypes.bool,
}

export default Curtain
