import React from 'react'
import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'

import FlexGrid from '../styled/FlexGrid'
import Tag from '../styled/Tag'

function Tags(props = {}) {
  return (
    <FlexGrid>
      {props.tags.map(tag => (
        <FlexGrid.Item key={tag}>
          <Tag as={Link} to={`/blog/tags/${kebabCase(tag)}/`}>
            {tag}
          </Tag>
        </FlexGrid.Item>
      ))}
    </FlexGrid>
  )
}

export default Tags
