import styled, { keyframes } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { system } from './lib/system'

const float = keyframes`
  0% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(20px, 20px) scale(0.8);
  }
  100% {
    transform: translate(30px, 10px) scale(1.2);
  }
`

const FancyHeader = styled.div`
  position: relative;

  svg > circle:nth-child(2) {
    animation: ${float} 6s ease-in-out infinite alternate;
  }

  svg > circle:nth-child(3) {
    animation: ${float} 6s ease-in-out infinite alternate;
  }

  svg > circle:nth-child(4) {
    animation: ${float} 8s ease-in-out infinite alternate;
  }

  svg > circle:nth-child(5) {
    animation: ${float} 8s ease-in-out infinite alternate;
  }

  ${system}
`

const FancyHeaderCircles = styled.div`
  display: none;
  left: -50px; /* FIXME: Magic number! */
  position: absolute;
  top: -50px; /* FIXME: Magic number! */
  z-index: -1;

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    display: block;
  }
`

FancyHeader.Circles = FancyHeaderCircles

export default FancyHeader
