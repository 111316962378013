/* eslint-disable react/forbid-foreign-prop-types */

import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  style,
  typography,
} from 'styled-system'

const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: 'text-decoration',
})

const textTransform = style({
  prop: 'textTransform',
  cssProperty: 'text-transform',
})

export const system = compose(
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  textDecoration,
  textTransform,
  typography
)

export const propTypes = {
  ...background.propTypes,
  ...border.propTypes,
  ...color.propTypes,
  ...flexbox.propTypes,
  ...grid.propTypes,
  ...layout.propTypes,
  ...position.propTypes,
  ...shadow.propTypes,
  ...space.propTypes,
  ...typography.propTypes,
}
