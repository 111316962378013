import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { system } from './lib/system'

const Main = styled.main`
  max-width: 56.25rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: ${themeGet('space.xxl')};
  padding-left: ${themeGet('space.base')};
  padding-right: ${themeGet('space.base')};

  @media screen and (min-width: ${themeGet('breakpoints.md')}) {
    padding-bottom: ${themeGet('space.xxxl')};
    padding-left: ${themeGet('space.l')};
    padding-right: ${themeGet('space.l')};
  }

  @media screen and (min-width: ${themeGet('breakpoints.lg')}) {
    padding-left: 0;
    padding-right: 0;
  }

  ${system}
`

export default Main
