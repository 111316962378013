import React from 'react'
import PropTypes from 'prop-types'

import Box from '../styled/Box'
import StyledFancyHeader from '../styled/FancyHeader'

function FancyHeader(props = {}) {
  return (
    <StyledFancyHeader {...props}>
      <Box>{props.children}</Box>
      <StyledFancyHeader.Circles>
        <svg
          width="250"
          height="250"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="50" cy="75" r="50" fill="rgba(114, 142, 166, 0.2)" />
          <circle cx="85" cy="110" r="30" fill="rgba(114, 142, 166, 0.1)" />
          <circle cx="85" cy="10" r="10" fill="rgba(114, 142, 166, 0.3)" />
          <circle cx="110" cy="40" r="5" fill="rgba(114, 142, 166, 0.2)" />
          <circle cx="30" cy="150" r="15" fill="rgba(114, 142, 166, 0.1)" />
        </svg>
      </StyledFancyHeader.Circles>
    </StyledFancyHeader>
  )
}

FancyHeader.propTypes = {
  ...Box.propTypes,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

export default FancyHeader
