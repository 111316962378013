import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

const Tag = styled.a`
  color: ${themeGet('colors.medium')};
  display: block;
  font-size: ${themeGet('fontSizes.xs')};
  position: relative;
  text-decoration: none;

  &::before {
    color: ${themeGet('colors.primary')};
    content: '# ';
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
`

export default Tag
